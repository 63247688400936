import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { routerReducer } from '@ngrx/router-store';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export const reducers: ActionReducerMap<RootState> = {router: routerReducer};

export const metaReducers: MetaReducer<RootState>[] = !environment.production
  ? []
  : [];
